import axios from "axios";
import {responses} from "@/utils/responses";

export default {
    name: 'tickets-tasks',
    state: {
        tasks: [],
        task: {}
    },
    getters: {
        getTask: (state) => state.task,
        getTasks: (state) => state.tasks
    },
    mutations: {
        setTasks: (state, data) => {
            state.tasks = data
        },
        setTask: (state, data) => {
            state.task = data
        }
    },
    actions: {
        getTaskByID: (context, payload) => {
            return axios.get(ticketApi + `ticket/task/${payload.ticket}/${payload.id}`)
                .then(({data}) => context.commit('setTask', data));
        },

        getTaskByUserID: (context, userID) => {
            return axios.get(ticketApi + `ticket/task/*/openTask/${userID}`)
                .then(({data}) => data);
        },
        createTask: (context, data) => {
            return axios.post(ticketApi + `ticket/task/${data.ticket}`, data)
                .then(({data}) => data)
                .catch(err => {
                    let error = errorHandler.throwGenericFailed()
                    responses.throwErr(error);
                    throw new Error(error);
                })
        },
        editTask: (context, data) => {
            return axios.put(ticketApi + `ticket/task/${data.ticket}/${data.id}`, data)
                .then(({data}) => data)
                .catch(err => {
                    let error = errorHandler.throwGenericFailed()
                    responses.throwErr(error);
                    throw new Error(error);
                })
        },
        deleteTask: (context, data) => {
            return axios.delete(ticketApi + `ticket/task/${data.ticket}/${data.id}`)
                .then(({data}) => context.commit('setTasks', data))
                .catch(err => {
                    let error = errorHandler.throwGenericFailed()
                    responses.throwErr(error);
                    throw new Error(error);
                })
        },
        completeTask: (context, data) => {
            return axios.post(ticketApi + `ticket/task/${data.ticket}/${data.id}/complete`)
                .then(({data}) => data)
                .catch(err => {
                    let error = errorHandler.throwGenericFailed()
                    responses.throwErr(error);
                    throw new Error(error);
                })
        }
    }
}
