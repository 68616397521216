import {responses} from "@/utils/responses";
import {utils} from "@/utils/utils";
import Swal from "sweetalert2";
import {errorHandler} from "@/services/errorHandler";
import {authentication} from "@/services/auth";
import {dateTimeManager} from "@/services/TimeManager";
import Vue from 'vue'
import axios from 'axios'


window.axios = axios;
Array.prototype.removeIf = function (callback) {
    let i = 0;
    while (i < this.length) {
        if (callback(this[i], i)) {
            this.splice(i, 1);
        } else {
            ++i;
        }
    }
};
window.printApi = `https://tom9.abc.co.zm/PrintApi/`;
window.ticketApi2 = `https://lna.abc.co.zm/PettyCashApi/api/v1/`;

// window.ticketApi = `http://localhost:8181/TicketApp/`
window.ticketApi = `https://lna.abc.co.zm/TicketApp/`
window.ticketApiV2 = ticketApi;

// window.synergy4 = 'http://192.168.1.222:8181/SynergyApi/' // required stock
window.synergy4 = 'https://lna.abc.co.zm/SynergyApi/' // required stock

// window.lytSIApi = 'http://192.168.1.222:8181/lytSI/api/v1/'
window.lytSIApi = 'https://lna.abc.co.zm/lytSI/api/v1/'

window.synergy6 = synergy4 + 'api/v2/'
window.synergy = synergy4 + 'api/v1/'
window.synergy1 = synergy4 + 'api/v1/' //all-pending-requests
window.synergy2 = synergy4 + 'api/v1/'// follow up customer
window.synergy3 = synergy4 // dispatch pending
window.synergy5 = synergy4 + 'api/v1/' // job card management

// window.subscriptionApi = `http://localhost:8080/subscription/api/`
window.subscriptionApi = `https://tom9.abc.co.zm/subscription/api/`

window.chatApi = `https://lna.abc.co.zm/ChatLinkChrilan/supportChat/`
// window.chatApi = `http://192.168.1.38:8443/ChatLinkChrilan/supportChat/`

window.registrationApi = `https://chrilantech.com/regmac/api/`

window.SearchTicket = ticketApi+`ticket/v2/`

window.serverApi = `https://lna.abc.co.zm/OnlineSalesServer/`
window.franchiseTomcatApi = `https://tom9.abc.co.zm/Franchise_OnlineServer/`

window.stocklistApi = `https://lna.abc.co.zm/FranchiseSpringServer/`

// window.stocklistApi = `http://localhost:8181/FranchiseSpringServer/`
window.stocklistApiimport = franchiseTomcatApi;

window.responses = responses;
window.Swal = Swal;
window.errorHandler = errorHandler;
window.authenticator = authentication;
Vue.prototype.$dateTimeManager = dateTimeManager;
Vue.prototype.$utils = utils;
window.$utils = utils;

window.encodeObjectAsUrlParams = function encodeObjectAsUrlParams(obj, prefix = '') {
    const encodedParams = [];
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            const paramName = prefix ? `${prefix}[${key}]` : key;
            if (value instanceof Object) {
                encodedParams.push(encodeObjectAsUrlParams(value, paramName));
            } else {
                const encodedValue = encodeURIComponent(value);
                encodedParams.push(`${paramName}=${encodedValue}`);
            }
        }
    }
    return encodedParams.join('&');
}

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1500,
    showCloseButton: true,
    customClass: 'toastr',
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});
window.Toast = Toast;

window.getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}
window.pageOptions = {
    enabled: true,
    mode: 'pages',
    perPage: 10,
    position: 'bottom',
    perPageDropdown: [10, 100, 200],
    dropdownAllowAll: false,
    jumpFirstOrLast: true,
    pageLabel: 'page', // for 'pages' mode
}

window.isValidString = (string) => {
    if (!string) return false;
    string = string.toString().trim();
    return !(string === `null` || string === `undefined` || string === ``);

}

window.generateUUID = () => {
    let uuid = '', i, random;
    for (i = 0; i < 32; i++) {
        random = Math.random() * 16 | 0;
        if (i === 8 || i === 12 || i === 16 || i === 20) {
            uuid += '-';
        }
        uuid += (i === 12 ? 4 : (i === 16 ? (random & 3 | 8) : random)).toString(16);
    }
    return uuid;
}