import {Storage} from "@/services/Storage";
import axios from "axios";
import {dateTimeManager} from "@/services/TimeManager";


export default {
    name: 'customers',
    state: {
        debtors: [],
        currentCustomer: {},
        currentStore: {}
    },
    getters: {
        validateCellNumber: () => (cellNumber) => {
            if (!cellNumber) return `N/A`;
            if (cellNumber.slice(0,2) === `26`){
                if (cellNumber.length === 12){
                    return `+${cellNumber}`
                }
                return cellNumber;
            }
            if (cellNumber.length === 10){
                return cellNumber;
            }
            return cellNumber;
        },
        getAllDebtors: (state) => state.debtors.sort(((a, b) => a.Debtor_Name.localeCompare(b.Debtor_Name))),
        getCurrentCustomer: (state) => state.currentCustomer,
        getCurrentStore: (state) => state.currentStore,
        getDebtorByID: (state) => (id) => {
            id = Number(id)
            if (id === 0) {
                return 'None';
            }
            let debtor = state.debtors.find(customer => customer.Customer_id === id);
            return debtor ? debtor.Debtor_Name : id;
        },
        isStoreOnline: (state) => (store) => {
            if (!store.lastOnline) {
                return false;
            }
            let last = new Date(store.lastOnline
                .replaceAll('T', ' ') + ':00');
            let now = new Date(dateTimeManager.getNow());
            return (now - last) <= 600000;
        },
    },
    mutations: {
        setDebtors: async (state, data) => {
            if (data) {
                await Storage.setDebtors(data)
            } else {
                data = await Storage.getAllDebtors();
            }
            state.debtors = data
        },

        setCurrentCustomer: (state, data) => state.currentCustomer = data,
        setCurrentStore: (state, data) => state.currentStore = data,
    },
    actions: {
        getAllDebtors: (context) => {
            return axios.get(ticketApi + `reg/Customer/search?search=`)
                .then(({data}) => context.commit('setDebtors', data))
                .catch(err=>errorHandler.tomcatError(err))
        },
        getStoresByCustomer: (context, id) => {
            if (!id) {
                return context.commit('setCurrentCustomer', {})
            }
            return axios.get(ticketApi + `reg/Customer/` + id)
                .then(({data}) => {
                    for (let i = 0; i < data.stores.length; i++) {
                        let storeID = data.stores[i].id;
                        let storeTickets = data.activeTickets.filter(ticket => ticket.store === storeID);
                        data.stores[i].tickets = [...storeTickets];
                    }
                    context.commit('setCurrentCustomer', data)
                }).catch(err=>errorHandler.tomcatError(err))
        },

        getCustomerInfo:(context , customerID)=>{
            return axios.get(ticketApi+`customer/v2/getInfo/${customerID}`)
                .then(({data})=>data).catch(err=>errorHandler.tomcatError(err))
        },

        getInvoices: (context,customerID) => {
            return axios.get(ticketApi+`customer/v2/getInvoices/${customerID}`)
                .then(({data}) => data)
        },

        getAllStores:(context , customerID)=>{
            return axios.get(ticketApi+`customer/v2/getStores/${customerID}`)
                .then(({data})=>data).catch(err=>errorHandler.tomcatError(err))
        },
        getCustomerTickets:(context , customerID)=>{
            return axios.get(ticketApi+`ticket/v2/getByCustomer/${customerID}`)
                .then(({data})=>data).catch(err=>errorHandler.tomcatError(err))
        },

        getStoreByID: (context, id) => {
            return axios.get(ticketApi + `reg/store/` + id)
                .then(({data}) => context.commit('setCurrentStore', data))
                .catch(err=>errorHandler.tomcatError(err))
        },

        getOnlineInvoicing: (context, id) => {
            return axios.get(subscriptionApi + `customerUpdate/getOnlineInvoicing/` + id)
                .then(({data}) => data).catch(err=>errorHandler.tomcatError(err))
        },

        setOnlineInvoicing:async (context, payload) => {
            let user = await Storage.getCurrentUser();
            payload.user = user.Username;
            return axios.put(subscriptionApi + `customerUpdate/updateOnlineInvoicing`, payload)
                .catch(err=>errorHandler.tomcatError(err))
        },

        getStoreKazangDetails: (context, storeDB) => {
            return axios.get(subscriptionApi + `customerUpdate/getKazangDetails/${storeDB}`)
                .then(({data}) => data).catch(err=>errorHandler.tomcatError(err))
        },

        updateStoreKazangDetails: (context, payload) => {
            return axios.post(franchiseTomcatApi + `SetKazangUser/${payload.storeDB}`,payload)
                .then(({data}) => data).catch(err=>errorHandler.tomcatError(err))
        },

        getTicketsStoreID: (context, id) => {
            return axios.get(ticketApi + `ticket/store/` + id)
                .then(({data}) => data).catch(err=>errorHandler.tomcatError(err))
        },

        addStoreToCustomer: (context, payload) => {
            let customerID = payload.mainCustomer;
            return axios.post(subscriptionApi + `customerUpdate/addStore/${customerID}`, payload)
                .then(({data}) => {
                    context.dispatch('getStoresByCustomer', customerID)
                    return data;
                }).catch(err=>errorHandler.tomcatError(err))
        },

        sendKazangSignUpDetails : (context,payload) => {
            return axios.post(`https://chrilantech.com/LytSoftPosOnline/api/email/sendKazangSignUp`,payload,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        },
    },
}
