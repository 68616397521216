import axios from "axios";

export default {
    name: 'chat',
    state: {
        loadingMessage: false
    },
    getters: {
        getMessageStatus: (state) => state.loadingMessage
    },
    mutations: {
        setMessageStatus: (state , data) => state.loadingMessage = data
    },
    actions: {
        getChatThread: (context, ticketID) => {
            return axios.get(ticketApi + `ai-support/getChatThread/${ticketID}`)
                .then(({data}) => {
                    if (data.sendInitialMessage){
                        let obj = {
                            chatThread : data.chatThread,
                            ticketID
                        }
                        return context.dispatch(`sendTicketToChat`,obj)
                            .then(() =>data.chatThread)
                    }
                    return data.chatThread;
                }).catch(err => errorHandler.tomcatError(err))
        },

        sendTicketToChat: (context, obj) => {
            return axios.get(ticketApi + `ai-support/getTicketMessage/${obj.ticketID}`)
                .then(({data}) => {
                    let payload = { chatThread : obj.chatThread  , message : data};
                    return context.dispatch(`sendMessage`,payload)
                }).catch(err => errorHandler.tomcatError(err))
        },
        sendMessage(context , payload){
            return axios.post(chatApi + `sendMessage/${payload.chatThread}`, {
                content: payload.message
            }).catch(err => errorHandler.tomcatError(err))
        },
        getChatMessages: async (context, ticketID) => {
            context.commit(`setMessageStatus` , true)
            const maxRetries = 1500; // Maximum number of retries
            let currentRetry = 0;
            const retryDelay = (attempt) => 3000; // Exponential backoff formula

            const makeRequest = async (attempt) => {
                try {
                    if (attempt === 0){
                        await new Promise(resolve => setTimeout(resolve, retryDelay(attempt)));
                    }
                    const response = await axios.get(ticketApi + `ai-support/getChatMessages/${ticketID}`);
                    context.commit(`setMessageStatus` , false)
                    return response.data; // Successfully received response, return it
                } catch (err) {
                    if (err.response && err.response.status === 503) {
                        // Check if we've reached the max number of retries
                        if (attempt < maxRetries) {
                            // Wait for a calculated delay before retrying
                            await new Promise(resolve => setTimeout(resolve, retryDelay(attempt)));
                            return makeRequest(attempt + 1); // Retry request
                        } else {
                            throw new Error('Max retries reached'); // Throw an error or handle max retries case as needed
                        }
                    } else {
                        context.commit(`setMessageStatus` , false)
                        errorHandler.tomcatError(errorHandler)// Rethrow other errors to be handled elsewhere
                    }
                }
            };

            return makeRequest(currentRetry);
        },
    },
}
