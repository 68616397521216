<template>
  <div id="app">
    <Loader />
    <router-view/>
  </div>
</template>

<script>

import Loader from "@/components/layout/Loader";
export default {
  components: {Loader},
  beforeMount() {
    this.refreshData();
  },
  methods: {
    refreshData() {
      return this.$store.dispatch('getPreLoginData', true);
    },
  },
}
</script>

<style lang="scss">
#app {
  height: 100%;
}

#app > div > div.content-wrap {
  overflow-y: auto;
}

</style>
