import {Storage} from "@/services/Storage";
import axios from "axios";

export default {
    name: 'email',
    state: {},
    getters: {},
    mutations: {},
    actions: {
        getAllEmailAddresses: async (context , DB) => {
            return axios.get(franchiseTomcatApi+`emailrecord/${DB}/getAllEmailAddresses`)
                .then(({data})=>data)
                .catch((err) => errorHandler.tomcatError(err))
        },

        addEmailToStore: async (context,payload) => {
            let storeDB = payload.DB;
            return axios.post(franchiseTomcatApi + `emailrecord/${storeDB}/addEmailToStore`,payload)
                .catch((err) => errorHandler.tomcatError(err))
        },

        removeEmailFromStore : async (context, payload) => {
            let storeDB = payload.DB;
            return axios.delete(franchiseTomcatApi + `emailrecord/${storeDB}/removeEmailFromStore`, {
                params: payload
            }).then(() => context.dispatch(`getAllEmailAddresses`)).catch((err) => errorHandler.tomcatError(err))
        }
    }
}
