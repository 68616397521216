import axios from "axios";

export default {
    name: 'print',
    state: {},
    getters: {},
    mutations: {},
    actions: {

        printUserTickets: (context, id) => {
            let url = registrationApi + `printUserTickets/${id}`;
            return context.dispatch(`openMaps`, url);
        },
        printPastDueByUser: (context, id) => {
            let url = registrationApi + `printPastDueByUser/${id}`;
            return context.dispatch(`openMaps`, url);
        },

        printAllOpenTickets: (context) => {
            let url = registrationApi + `printAllOpenTickets`;
            return context.dispatch(`openMaps`, url);
        },

        printTicketsDueToday: (context) => {
            let url = registrationApi + `printTicketsDueToday`;
            return context.dispatch(`openMaps`, url);
        },

        printAllPastDue: (context) => {
            let url = registrationApi + `printAllPastDue`;
            return context.dispatch(`openMaps`, url);
        },

        printTicketsClosedToday: (context) => {
            let url = registrationApi + `printTicketsClosedToday`;
            return context.dispatch(`openMaps`, url);
        },

        printCustomTicket: (context, payload) => {
            let params = encodeObjectAsUrlParams(payload);
            let url = registrationApi + `printCustomTickets?`+params;
            return context.dispatch(`openMaps`, url);
        }

    }
}
