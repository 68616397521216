<template>
  <div class="card shadow-lg rounded-3 py-3" style="border-radius:0; border:none">
    <div class="card-body p-1">
      <h1 class="card-title mb-3 text-dark text-uppercase" style="font-weight:700"><span
          style="color: #4169e1">Log</span> In</h1>

      <form @submit.prevent="login">
        <div class="row">
          <div class="col">
            <div class="form-floating mb-3">
              <input id="floatingTextInput1" v-model="form.Username"
                     class="form-control" placeholder="Username" required
                     type="text">
              <label for="floatingTextInput1">Username</label>
            </div>
          </div>
        </div>
        <div class="form-floating mb-3">
          <input id="floatingPasswordInput"
                 v-model="form.password" autocomplete="on"
                 class="form-control" placeholder="Password"
                 required type="password">
          <label for="floatingPasswordInput">Password</label>
        </div>
        <div class="text-center">
          <p class="text-danger">{{ warning }}</p>
          <button :disabled="loading" class="login-btn primary" type="submit">
            Login
            <span v-if="loading" aria-hidden="true" class="spinner-grow spinner-grow-sm" role="status"></span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginForm',
  beforeMount() {
    this.checkOnline();
  },
  data() {
    return {
      loading: false,
      show_password: false,
      form: {
        Username: "",
        password: ""
      },
      warning: ''
    }
  },
  methods: {
    checkOnline() {
      return this.$nextTick(() => {
        window.setInterval(() => {

          if (this.warning === `Internet Disconnected!!`) {
            this.warning = ``;
          }

          if (!this.warning && !navigator.onLine) {
            this.warning = `Internet Disconnected!!`;
          }

        }, 200);
      })
    },
    login() {
      this.loading = true;
      return this.$store.dispatch('userLogin', this.form)
          .catch((err) => this.warning = err)
          .finally(() => this.loading = false);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.card {
  padding-left: 10px;
  padding-right: 10px;
  width: 400px;
  max-width: 95vw;
  margin-top: -3rem !important;
}

.login-btn {
  color: white;
  display: block;
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 7px 18px rgba(11, 102, 255, 0.3) !important;

}
</style>
